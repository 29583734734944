import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import authReducer from "./auth/reducer"
import homeReducer from "./home/reducer"

import userClientReducer from "./userClient/reducer"
import roleAndPermissionReducer from "./roleAndPermission/reducer"
import userManagementReducer from "./user_management/reducer"

import postReducer from "./post/reducer"
import projectReducer from "./project/reducer"
import jobReducer from "./job/reducer"
import requestReducer from "./request/reducer"
import serviceReducer from "./service/reducer"
import imageReducer from "./image/reducer"
import memberReducer from "./member/reducer"
import emailReducer from "./notify_email/reducer"

export const rootReducer = combineReducers({
  auth: auth.reducer,
  authReducer: authReducer,
  homeReducer: homeReducer,
  requestReducer: requestReducer,
  userClientReducer,
  roleAndPermissionReducer,
  userManagementReducer,
  postReducer,
  projectReducer,
  jobReducer,
  serviceReducer,
  imageReducer,
  emailReducer,
  memberReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
